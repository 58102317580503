@import "assets/scss/main";

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%; // 1rem = 10px; 10px/16px
  width: 100%;
  overflow-x: hidden;

  @include respond(tab-land) { //width < 1200
    font-size: 56.25%; // 1rem = 9px; 9px/16px
  }

  @include respond(tab-port) { //width < 900
    font-size: 50%; // 1rem = 8px; 8px/16px
  }

  @include respond(big-desktop) {
    font-size: 75%; // 1rem = 12px; 12px/16px
  }
}

body {
  box-sizing: border-box;
  overflow-x: hidden;
}

select {
  text-decoration: none;
}

textarea {
  resize: none;
}

Link {

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
}
