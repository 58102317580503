@keyframes cursor {
  from {
    border-color: #81dbdb;
  }
  to {
    border-color: transparent;
  }
}

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}


@keyframes bigger {
  0% {
    color: $color-primary-light;
  }

  50% {
    color: $color-primary;
  }

  100% {
    color: $color-primary-light;
  }
}

@keyframes rotateWordsSecond {
  0% {
    opacity: 1;
    animation-timing-function: ease-in;
    width: 0;
  }
  10% {
    opacity: 0.3;
    width: 0;
  }
  20% {
    opacity: 1;
    width: 80%;
  }
  27% {
    opacity: 1;
    width: 100%;
  }
  35% {
    opacity: 0.4;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

