.btn {
  text-decoration: none;
  padding: 1.5rem 4rem;
  margin-left: 6px;
  display: inline-block;
  border-radius: 10rem;
  transition: all .2s;
  position: relative;
  font-size: $default-font-size;
  background-color: $color-white;
  color: $color-black;
  border: none;
  cursor: pointer;

  @include respond(lap-small) {
    display: block;
    margin-top: 5px;
  }

  &-influencer,
  &-brand {

    &:hover {
      background-color: $color-secondary;
      color: $color-primary
    }
  }

  &-submit {
    background-color: $color-primary;
    color: $color-white;
  }
}

