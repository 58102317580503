@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');

.service-img {
  margin-left: 10rem;

  @include respond(tab-land) {
    display: none;
  }
}

//.services {
//  padding: 0 6rem;
//  display: flex;
//  flex-wrap: wrap;
//
//  @include respond(tab-land) {
//    padding: 0;
//  }
//
//  &__card {
//    width: 25%;
//    text-align: center;
//    padding: 1rem;
//    transition: all .2s;
//    border-radius: 40px;
//    cursor: pointer;
//
//    @include respond(tab-land) {
//      width: 25%;
//    }
//
//    @include respond(tab-port) {
//      width: 50%;
//    }
//
//    @include respond(phone) {
//      width: 100%;
//    }
//
//    h3 {
//      font-family: 'Montserrat', sans-serif;
//    }
//
//    &-icon {
//      width: 200px;
//    }
//
//    &-about {
//      font-size: 1.5rem;
//      font-weight: 100;
//      text-align: justify;
//      padding: 1rem;
//    }
//
//    &:hover {
//      transform: scale(1.05);
//      box-shadow: 0 1rem 2rem rgba($color-black, 0.4);
//    }
//  }
//}

