@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');


.careers {

  &__background {
    width: 100%;
    height: 45vh;
    background-image: url(../../images/Career.png);
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__heading {
    font-size: 4rem;
    padding-top: 11rem;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
  }

  &__content {
    color: $color-primary;
    text-align: center;
    font-size: 2rem;
    margin: 0 auto;
    padding-top: 10rem;
    font-family: 'Montserrat', sans-serif;
  }

  &-link:active,
  &-link:hover,
  &-link:visited,
  &-link:link {
    text-decoration: none;
    animation: bigger .5s infinite;
    transition: all .2s;
    color: $color-primary-light;
  }
}
