.slider {

  &__box {
    padding: 0 4rem;
  }
}

.client {

  &__card {
    padding: 2rem;
    text-align: center;
    box-shadow: 1rem 2rem 4rem rgba($color-black, 0.4);
    border-radius: 10px;
    background-color: $color-white;
  }

  &__img {
    width: 12rem;
  }

  &__details {

    &-name {
      font-size: 2rem;
      font-weight: 100;
    }

    &-work {
      font-size: 1rem;
      font-weight: 100;
      margin-top: -8px;
    }
  }

  &__comment {
    font-size: 2rem;
    font-weight: 100;
  }
}