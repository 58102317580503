.ticker-wrap {
  width: 100%;
  overflow: hidden;
  padding-left: 100%;
  box-sizing: content-box;

  img {
    width: 12rem;
  }

  .ticker {
    display: inline-block;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    text-align: center;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: $duration;
    animation-duration: $duration;

    &__item {
      display: inline-block;
      padding: 0 2rem;
      font-size: 2rem;
      color: black;
    }
  }
}

.beardo {
  width: 13rem;
}
