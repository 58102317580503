@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

//Media queries
/*
0 - 400px: Phone
400-600px: Tablet Portrait
600-900px: Tablet Landscape
900-1200: Laptop-small
[1200 - 1800px] where normal styles apply
1800px + : Big Desktop

$breakpoint argument choices:
- phone
- tab-port
- tab-land
- small-laptop
- big-desktop

NOTE = REM IS NOT SUPPORTED BY MOST OF THE BROWSERS FOR MEDIA QUERIES SO WE USE EM
REM AND EM ARE NOT AFFECTED BY THE ROOT FONT SIZE

1EM = 16PX  (if user has not defined the font-size)
 */
@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 25em) { //400px
      @content
    }
  }

  @if $breakpoint == tab-port {
    @media (max-width: 37.5em) { //600px
      @content
    }
  }

  @if $breakpoint == tab-land {
    @media (max-width: 56.25em) { //900px
      @content
    }
  }

  @if $breakpoint == lap-small {
    @media (max-width: 75em) { //1200px
      @content
    }
  }

  @if $breakpoint == big-desktop {
    @media (min-width: 112.5em) { //1800px
      @content
    }
  }
}
