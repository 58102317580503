//Colors
$color-primary: #214782;
$color-secondary: #fff406;
$color-primary-light: #5875A1;
$color-primary-dark-1: #005c97;
$color-primary-dark-2: #363795;
$color-bg: #e5e5e5;
$color-white: #fff;
$color-black: #000;
$color-black-light: #333030;
$color-gray: #c4c4c4;
$color-gray-light: #eee;
$color-blue-light: #EAEEFF;
$color-red: #e60000;

//Grid
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal: 6rem;

//Font-size
$default-font-size: 1.6rem;

//Time
$duration: 20s;
