.content-brand {
  height: 100vh;
  display: flex;

  &__left-side {
    width: 50vw;
    background-color: $color-primary-light;
    background-image: url(../../images/Influencer_form_image.png);
    background-size: cover;
    background-repeat: no-repeat;

    &__logo {
      width: 40rem;
    }

    &__text {
      padding: 0 5rem;

      &-heading {
        font-size: 3rem;
        font-weight: 200;
        color: $color-white;
      }

      &-paragraph {
        font-size: 2rem;
        font-weight: 100;
        color: $color-white;
      }
    }

    @include respond(tab-land) {
      display: none;
    }
  }

  &__right-side {
    width: 50vw;

    @include respond(tab-land) {
      width: 100vw;
    }

    &__about {
      display: flex;
      justify-content: flex-end;
      padding: 3rem;

      &-text {
        font-size: 2rem;
        color: $color-black-light;
      }
    }
  }
}

.influencer-form {
  width: 70%;
  margin: 0 auto;
}


.form {
  padding: 2rem;

  &__group:not(:last-child) {
    margin-bottom: 2rem;
  }

  &__input {
    font-size: 1.5rem;
    font-family: inherit;
    color: inherit;
    padding: 1.5rem 2rem;
    border-radius: 2px;
    background-color: $color-gray-light;
    border: none;
    border-bottom: 3px solid transparent;
    width: 100%;
    display: block;
    transition: all .3s;

    &:focus {
      outline: none;
      box-shadow: 0 1rem 2rem rgba($color-black, .1);
      border-bottom: 3px solid $color-primary;
    }

    &:focus:invalid {
      border-bottom: 3px solid $color-red;
    }

    &::-webkit-input-placeholder {
      color: $color-primary-light;
    }
  }

  &__label {
    font-size: 1.2rem;
    font-weight: 700;
    margin-left: 2rem;
    margin-top: .7rem;
    display: block;
    transition: all .3s;
  }

  &__input:placeholder-shown + &__label { //adjacent sibling selector
    opacity: 0;
    visibility: hidden;
    transform: translateY(-4rem);
  }
}




