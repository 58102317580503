@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');

body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  line-height: 1.7;
}

.typewriter {
  color: $color-primary;
  padding: 0 1rem;
  transition: all;
  animation: cursor 0.5s ease-in-out infinite;
}

.heading-primary {
  text-transform: uppercase;
  font-size: 4.5rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;

  &--white {
    color: $color-white;
  }
}

.heading-secondary {
  font-size: 4rem;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;

  &--white {
    color: $color-white;
  }
}

.heading-tertiary {
  font-size: 2.25rem;
  font-weight: 200;

  &--white {
    color: $color-white;
  }
}

.heading-sub {
  font-size: 2.5rem;
  font-weight: 500;
  margin-top: -3rem;
  font-family: 'Montserrat', sans-serif;

  &--white {
    color: $color-white;
  }
}

.paragraph {
  font-size: $default-font-size;
  font-weight: 400;
  text-align: left;
  font-family: 'Montserrat', sans-serif;

  @include respond(phone) {
    font-size: 2.3rem;
  }

  &--white {
    color: $color-white;
  }

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
}
